import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

export default api;

export const getNickname = async () => {
  const res = await api.get("/client/main", { withCredentials: true });
  return res.data;
};
